var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"pa-5"},[_c('Overlay',{attrs:{"loading":_vm.loading}}),_c('v-col',{staticClass:"pl-1",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"pl-0",attrs:{"text":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{staticClass:"pr-1"},[_vm._v("mdi-chevron-left")]),_vm._v(" Voltar ")],1),_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('span',{staticClass:"text-h4"},[_vm._v(" Fila de espera ")])]),_c('v-row',{attrs:{"no-gutters":""}},[_c('span',{staticClass:"caption font-weight-light pt-0 pb-0"},[_vm._v(" Fila de espera para atendimentos dos chamados ")])])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"server-items-length":_vm.paginationParams.total,"headers":_vm.alertHeaders,"items":_vm.alerts,"ino-data-text":"Nenhum dado encontrado","item-key":"id","sort-by":"id"},on:{"pagination":_vm.pagination},scopedSlots:_vm._u([{key:"item.alertable_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.menu",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.teste(item)}}},[_c('v-list-item-title',[_vm._v("Subir na lista de espera ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }