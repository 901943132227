<template>
  <v-row class="pa-5">
    <Overlay :loading="loading" />
    <v-col class="pl-1" cols="12">
      <v-btn class="pl-0" text @click="$router.go(-1)">
        <v-icon class="pr-1">mdi-chevron-left</v-icon>
        Voltar
      </v-btn>
      <v-col cols="12" class="mt-3">
        <v-row>
          <v-col cols="12">
            <v-row no-gutters>
              <span class="text-h4"> Fila de espera </span>
            </v-row>
            <v-row no-gutters>
              <span class="caption font-weight-light pt-0 pb-0">
                Fila de espera para atendimentos dos chamados
              </span>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-data-table
          @pagination="pagination"
          :server-items-length="paginationParams.total"
          :headers="alertHeaders"
          :items="alerts"
          ino-data-text="Nenhum dado encontrado"
          item-key="id"
          sort-by="id"
        >
          <template v-slot:item.alertable_id="{ item }">
            {{ item.name }}
          </template>

          <template v-slot:item.menu="{ item }">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="teste(item)">
                  <v-list-item-title
                    >Subir na lista de espera
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import Overlay from "../../../components/Overlay/Overlay.vue";

export default {
  components: { Overlay },
  data: () => ({
    page: 1,
    paginationParams: {
      page: 1,
      per_page: 10,
      total: 0,
      // sort_by: "id",
      // sort_direction: "asc"
    },
    loading: false,
    alerts: [],
    patient: {},
  }),
  mounted() {
    this.pagination();
  },
  methods: {
    pagination({ page, itemsPerPage }) {
      this.paginationParams.per_page = itemsPerPage;
      this.paginationParams.page = page;
      this.getAlerts();
      // console.log(this.page);
    },
    formatLastUpdate(date) {
      if (!date) return "";

      const day = date.slice(8, 10);
      const month = date.slice(5, 7);
      const year = date.slice(0, 4);
      const time = date.slice(11, 19);

      return `${day}/${month}/${year} às ${time}`;
    },
    async getAlerts() {
      this.loading = true;
      try {
        const response = await this.$axios("alerts/", {
          params: this.paginationParams,
        });
        if (response.data) {
          this.paginationParams.total = response.data.pagination_params.total;
          this.alerts = response.data.data.map((alert) => {
            // console.log(alert.status);
            if (!alert.alertable_type || alert.alertable_type == null) {
              alert.alertable_type = alert.status;
            }
            return {
              id: alert.id,
              patient: alert.user.name,
              alertable_type: this.setType(alert.alertable_type),
              subject_matter: alert.subject_matter,
              created_at: this.formatLastUpdate(alert.created_at),
              responsible: alert.responsible,
              risk: this.setRisk(alert.risk),
            };
          });
        }

        this.loading = false;
      } catch (error) {
        this.loading = false;
        // console.log("mensagem de error" + error);
      }
    },
    setType(value) {
      const type = {
        TemperatureExam: {
          text: "Temperatura",
          value: "TemperatureExam",
        },
        ElectroCardiogram: {
          text: "Eletro Cardiograma",
          value: "ElectroCardiogram",
        },
        falldown_exam: {
          text: "Alerta de queda",
          value: "falldown_exam",
        },
        sos_exam: {
          text: "S.O.S Pressionado",
          value: "sos_exam",
        },
        fence_in_exam: {
          text: "Fora de Cerca",
          value: "fence_in_exam",
        },
        fence_out_exam: {
          text: "Area Limite",
          value: "fence_out_exam",
        },
        low_battery_exam: {
          text: "Pouca Bateria",
          value: "low_battery_exam",
        },
        device_offline_exam: {
          text: "Dispositivo offline",
          value: "device_offline_exam",
        },
      };

      return (
        type[value] || {
          text: "Motivo não encontrado",
        }
      );
    },
    setRisk(value) {
      const type = {
        high: {
          text: "Acima do normal",
          value: "high",
        },
        low: {
          text: "Abaixo do normal",
          value: "low",
        },
      };

      return (
        type[value] || {
          text: "Motivo não encontrado",
        }
      );
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.authentication.currentUser,
    }),
    alertHeaders() {
      return [
        { text: "Paciente", value: "patient", align: "center", sortable: false, },
        { text: "Motivo", value: "alertable_type.text", align: "center", sortable: false, },
        { text: "Tipo do alerta", value: "risk.text", align: "center", sortable: false, },
        {
          text: "Data e Horario do alerta",
          value: "created_at",
          align: "center",
          sortable: false,
        },
        // { text: "Ação", value: "menu", width: "2%", align: "center" },
      ];
    },
  },
};
</script>

<style></style>
